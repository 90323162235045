footer {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 80px 0 0 0;
  padding-bottom: 20px;
  background-color: whitesmoke;
  box-shadow: 0 0 20px 20px whitesmoke;

  .logo {
    background-image: url('/../public/img/logos/logo_title.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 60px;
    width: 300px;
    filter: grayscale(1);
    opacity: 0.6;
    margin: 20px;
    margin-bottom: 40px;
    transition: opacity 0.2s ease-in-out;
  }

  .logo:hover {
    opacity: 1;
  }

  div.icons {
    width: 100%;
    margin-bottom: 10px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    a {
      margin: 5px;
      display: block;
      width: 32px;
      height: 32px;
      background-size: contain;
      filter: grayscale(1);
      transition: filter 0.06s ease-in-out;
    }

    a:hover {
      filter: grayscale(0);
    }

    a.instagram {
      background-image: url('/../public/img/icons/icons8-instagram-100.png');
    }

    a.facebook {
      background-image: url('/../public/img/icons/icons8-facebook-100.png');
    }

    a.youtube {
      background-image: url('/../public/img/icons/icons8-play-button-100.png');
    }

    a.discord {
      background-image: url('/../public/img/icons/icons8-discord-100.png');
    }

    a.status {
      background-image: url('/../public/img/icons/icons8-signal-100.png');
    }
  }

  p {
    margin: 3px;
    color: rgba(0, 0, 0, 0.4);
    text-align: center;

    a {
      color: rgba(0, 0, 0, 0.4);
      font-weight: bold;
      text-decoration: none;
    }

    a:hover {
      color: rgba(0, 0, 0, 0.8);
    }
  }

  p.poweredby {
    margin-bottom: -10px;
  }
}
