$color1: #ff7f66;
$color2: #de1444;

.contact-form {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    margin: 1rem;
    padding: 0 1rem 1rem 1rem;
    background-color: white;
    border: 1px solid #bbb;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba($color: #000000, $alpha: 0.2);
    color: #363636;

    @media (min-width: 600px) {
        margin: 1rem auto;
        padding: 0 2rem;
        max-width: 60ch;
    }

    &__legend {
        font-size: 2.75em;
        font-weight: 700;
        text-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.2);
    }

    &__label {
        margin: 0 0.5em 0.2em;
        font-size: 1.4em;
        font-weight: 500;
    }

    &__input {
        display: grid;
        grid-template-columns: 1.5em 1fr;
        align-items: center;
        margin: 0 0.5em 0.5em;
        //3px for the box shadow
        padding: 3px 0.5em;
        font-size: 1.2em;
        border: 1px solid #bbb;
        border-radius: 5px;
        box-shadow: inset 0 0 3px rgba($color: #000000, $alpha: 0.2);

        &__field {
            padding: 0.75em 0.5em;
            // font: inherit;
            // width: 90%;
            font-size: 1em;
            border: none;
        }

        &__icon {
            max-height: 1.5em;
            text-align: center;
            filter: opacity(0.5);
        }
    }

    &__message {
        min-height: 8ch;
        margin: 0 0.5em 0.5em;
        // for some reason margin doesn't work correctly
        // on the right side on some mobile browsers
        //width: calc(100% - 1.5rem);
        width: 100%;
        border: 1px solid #bbb;
        border-radius: 5px;
        //box-shadow: inset 0 2px 3px rgba($color: #000000, $alpha: 0.2);
        font: inherit;
        font-size: 1.2em;
        resize: vertical;
    }

    &__button {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 0.5em;
        height: 5em;

        &__btn {
            display: flex;
            align-items: center;
            padding: 0.4em 0.6em;
            background: linear-gradient(110deg, $color1, $color2);
            border: none;
            border-radius: 10px;
            color: whitesmoke;
            font-size: 1.8em;
            transition: all 0.2s ease-out;
        }

        @media (hover: hover) {
            &__btn:hover {
                transform: scale(1.1);
            }
        }

        &__icon {
            margin-left: 0.2em;
            max-height: 1.5em;
            text-align: center;
            filter: invert(1) opacity(0.9);
        }
    }

    &__required::after {
        content: " (wymagane)";
        display: inline;
        color: gray;
        font-size: 0.8em;
    }
}

.modal {
    // display: flex;
    // flex-flow: column nowrap;
    // align-items: center;
    display: none;
    grid-template-columns: 80px 1fr;
    grid-template-rows: 4em 1fr;
    grid-template-areas:
        "icon header"
        "icon message";
    align-items: start;
    column-gap: 0.25rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    padding: 1rem 1rem 2rem;
    width: 90%;
    color: #444;
    background-color: white;
    border-radius: 15px;
    //transition: all 200ms ease-out;
    animation-name: pop;
    animation-timing-function: ease-out;
    animation-duration: 0.5s;
    animation-fill-mode: backwards;
    z-index: 4;

    @media (min-width: 600px) {
        grid-template-columns: 100px 1fr;
        column-gap: 1rem;
        max-width: 50ch;
    }

    &__close-button {
        position: absolute;
        top: 1rem;
        right: 1rem;
        padding: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;
    }

    &__close-button::after {
        content: "";
        display: block;
        background-image: url("/../public/img/icons/icons8-macos-close-100.png");
        background-size: 2.5em;
        background-repeat: no-repeat;
        background-position: center center;
        width: 2.5em;
        height: 2.5em;
        transition: all 100ms ease-out;
        filter: opacity(0.7);
    }

    @media (hover: hover) {
        &__close-button:hover::after {
            transform: scale(1.2);
        }
    }

    &__icon {
        display: none;
        grid-area: icon;
        align-self: end;
        width: 100%;
        animation-name: pop-up;
        animation-timing-function: ease-out;
        animation-duration: 375ms;
        animation-delay: 400ms;
        animation-fill-mode: backwards;
    }

    &__icon.active {
        display: inline;
    }

    &__header {
        grid-area: header;
        height: 100%;
        font-size: 2.5em;
        font-weight: 600;
        //text-shadow: 0 0 20px rgba($color: #000000, $alpha: 0.4);
    }

    &__message {
        grid-area: message;
        font-weight: 500;
        font-size: 1.4em;
        margin: 0;
    }

    &__background-overlay {
        display: none;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba($color: #000000, $alpha: 0.5);
        animation-name: fade-in;
        animation-timing-function: ease-out;
        animation-duration: 200ms;
        animation-fill-mode: backwards;
        //transition: all 200ms ease-out;
        z-index: 3;
    }

    &__background-overlay.active {
        display: block;
    }
}

.contact-card {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin: 1rem 1rem 2rem;
    max-width: 60ch;
    padding: 1rem 2rem;
    background-color: white;
    box-shadow: 0 0 20px rgba($color: #000000, $alpha: 0.2);
    border-radius: 10px;

    @media (min-width: 600px) {
        margin: 1rem auto 2rem;
    }

    &__title {
        //ext-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
        color: #363636;
        font-weight: 600;
    }

    &__social {
        display: flex;
        justify-content: center;

        &__instagram {
            background-image: url("/../public/img/icons/icons8-instagram-100.png");
        }

        &__facebook {
            background-image: url("/../public/img/icons/icons8-facebook-100.png");
        }

        &__youtube {
            background-image: url("/../public/img/icons/icons8-play-button-100.png");
        }

        a {
            display: block;
            height: 50px;
            width: 50px;
            margin: 0 0.5rem;
            background-size: contain;
            transition: all 100ms ease-out;
        }
        @media (hover: hover) {
            a {
                filter: grayscale(1);
            }
            a:hover {
                filter: grayscale(0);
                transform: scale(1.1);
            }
        }
    }
}

.modal.active {
    display: grid;
}

a {
    color: #3080e0;
    text-decoration: none;
}

.flex-container {
    display: flex;
}

.soon {
    font-size: 25px;
    animation-name: fade-in;
    animation-delay: 2s;
    animation-timing-function: ease-out;
    animation-duration: 2s;
    animation-fill-mode: backwards;
}

.image {
    width: 100vw;
    animation-name: fade-in;
    animation-delay: 1s;
    animation-timing-function: ease-out;
    animation-duration: 1s;
    animation-fill-mode: backwards;

    @media (min-width: 500px) {
        width: 500px;
    }
}

@keyframes pop {
    from {
        transform: translate(-50%, -50%) scale(0.2);
        opacity: 0;
    }
    to {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}

@keyframes pop-up {
    0% {
        transform: scale(0);
    }
    75% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
