@import "../../../shadows.scss";

#merch_container {
  max-width: 1500px;
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-card-container {
  width: 100%;
}

.product-card {
  @include shadow("nonclikable");
  margin: 20px auto;
  background-color: white;
  border-radius: 20px;
  width: 100%;
  max-width: 900px;
  display: grid;
  grid-template-columns: 1.5fr 4fr;
  grid-template-rows: 4em auto;
  grid-column-gap: 0;
  grid-row-gap: 0;
  overflow: hidden;

  .name {
    grid-area: 1 / 2 / 2 / 3;
    margin: auto;
    font-size: 2em;
    align-self: center;
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    font-weight: 700;
  }

  .preview {
    grid-area: 2 / 1 / 3 / 2;
    padding: 0 20px;
    margin: 0 auto 20px;
    width: 90%;
    overflow: hidden;
  }

  .details {
    grid-area: 2 / 2 / 3 / 3;
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 2fr;
    gap: 0;
    margin: 0 20px;

    .desc {
      grid-area: 1 / 1 / 2 / 3;
      margin-right: 20px;
    }

    .colors {
      grid-area: 2 / 1 / 3 / 2;
      padding: 0;
      margin: 0 0 20px;

      li {
        list-style-position: inside;
      }
    }

    .sizes {
      grid-area: 2 / 2 / 3 / 3;
      border: 1px solid gray;
      margin-bottom: 20px;
      margin-right: 20px;
      border-collapse: collapse;

      thead {
        background-color: #f74a55;
        background-image: linear-gradient(90deg, #ff512f, #dd2476);
        color: whitesmoke;
      }

      th, td {
        border: 1px solid gray;
        border-collapse: collapse;
        text-align: center;
        vertical-align: middle;
        padding: 5px;
      }
    }
  }

  .price {
    position: absolute;
    width: 100px;
    height: 100px;
    text-align: center;
    vertical-align: middle;
    line-height: 100px;
    background-color: red;
    display: inline-block;
    z-index: 2;
    color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 24px;
    border-radius: 50px;
    transform: rotate(-45deg) translate(0, -20px);
    background-image: linear-gradient(90deg, #ff512f, #dd2476);
  }
}


@media only screen and (max-width: 660px) {
  #merch_container {
    max-width: 100%;
  }

  .product-card-container {
    margin: 0 20px;
    font-size: 14px;
  }

  .product-card {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 4em auto auto;
    grid-row-gap: 20px;
    width: 100%;

    .name {
      grid-area: 1 / 1 / 2 / 2;
      width: 50%;
      text-align: center;
      padding-top: 10px;
    }

    .preview {
      grid-area: 2 / 1 / 3 / 2;
      margin: 0 auto;
    }

    .details {
      grid-area: 3 / 1 / 4 / 2;
      grid-template-rows: auto auto auto;
      grid-template-columns: 1fr;

      .desc {
        grid-area: 1 / 1 / 2 / 2;
        text-align: center;
        margin: auto;
      }

      .colors {
        grid-area: 2 / 1 / 3 / 2;
        text-align: left;
        margin: 20px auto;
      }

      .sizes {
        grid-area: 3 / 1 / 4 / 2;
        table-layout: fixed;
        width: 100%;
        margin: 0 auto 20px;
        font-size: 10px;
      }
    }
    .price {
      width: 75px;
      height: 75px;
      line-height: 75px;
      font-size: 16px;
    }
  }
}

