@import "../../../..//shadows.scss";

$colour1: #ff7f66;
$colour2: #de1444;

.page-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    max-width: 1500px;
    margin: auto;
}

.vertical-line {
    position: fixed;
    width: 5px;
    height: 200%;
    margin-left: 40px;
    margin-top: -500px;
    background-color: rgba($color: #000, $alpha: .2);
    z-index: -1;
}

.schedule-entry {
    display: grid;
    grid-template-columns: 120px 1fr; //120px 1fr;
    gap: 1em 1em;
    margin: 15px 10px 0 10px;
    max-width: 1000px;

    @media (max-width: 900px) {
        grid-template-columns: 100px 1fr;
    }
}

.schedule {
    margin-top: 50px;
    &__timespan {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        margin: auto;
        border-radius: 15px;
        //background-color: #ff7f66;
        background: linear-gradient(135deg, $colour1, $colour2);
        color: white;
        text-align: center;
        font-size: 1.75em;// 18px;
        font-weight: 500;
        text-shadow: 0 0 14px rgba(0, 0, 0, 0.5);
        animation: fade-in 650ms ease-out;
        animation-delay: calc(var(--order, 5) * 100ms);
        animation-fill-mode: backwards;
        z-index: 1;

        @media (max-width: 900px) {
            font-size: 1.75em;// 16px;
        }
    }

    &__section {
        margin: 10px 0;
        animation: slide-in 750ms ease-out;
        animation-delay: calc(calc(var(--order, 5) * 100ms) + 400ms);
        animation-fill-mode: backwards;
    }

    &__section-header {
        margin-bottom: 20px;
        color: #363636;
        font-weight: 700;
        font-size: 25px;
    }

    &__section-subheader {
        margin: 10px auto;
        color: #363636;
        font-weight: 600;
        font-size: 22px;
    }

    &__section-subheader::before {
        content: "";
        display: block;
        height: 1px;
        width: 85%;
        background-color: rgba($color: #000000, $alpha: .2);
        animation: stretch-out 1000ms ease-out;
        //animation-delay: calc(calc(var(--order, 5) * 100ms) + 600ms);
        animation-delay: 500ms;
        animation-fill-mode: backwards;
    }

    &__section-text {
        margin: 0;
        color: #363636;
        font-weight: 400;
        font-size: 1.2rem;
    }

}

.soon {
    font-size: 1.5em;
    animation: fade-in 1.5s ease 1s 1 normal backwards;
}

@keyframes stretch-out {
    from {
        width: 10%;
    }
    // 50% {
    //     width: 40%;
    // }
    to {
        width: 85%;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slide-in {
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        transform: translateX(0px);
    }
}
