$colour1: #ff7f66;
$colour2: #de1444;
$wifi_header_height: 75px;
.wifi-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    //-10px on top to move closer to the header
    margin: -10px auto 0 auto;
    max-width: 1000px;
    height: $wifi_header_height;
    background-color: #de1444;
    border-radius: 0 0 $wifi_header_height $wifi_header_height;
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.5);
    z-index: 2;

    @media (max-width: 900px) {
        position: static;
        flex-flow: column wrap;
        height: auto;
        border-radius: 0 0 50px 50px;
    }
}

$rounded_buttons: false;
@if $rounded_buttons {
    .wifi-header__button {
        margin: 0 20px;
        min-width: 50px;
        padding: 10px;
        border-radius: 10px;
        //background-color: #ff7f66;
        background: linear-gradient(160deg, $colour1, $colour2);
        color: white;
        text-align: center;
        text-decoration: none;
        font-size: 24px;
        font-weight: 500;
        transition: all 0.2s ease;
        cursor: pointer;
    }
} @else {
    .wifi-header__button {
        margin: 0 10px;
        padding: 10px;
        min-width: 50px;
        color: white;
        text-decoration: none;
        text-align: center;
        font-size: 24px;
        font-weight: 500;
        transition: all 0.2s ease;
        cursor: pointer;

        @media (max-width: 900px) {
            padding: 0;
            margin-bottom: 0.2em;
        }

        // &.active {
        //     font-weight: 600;
        //     // text-decoration: underline;
        //     border-bottom: 1px solid white;
        // }
    }

    .vertical-spacer {
        display: none;
        width: 5px;
        height: 100%;
        background-color: #b9143b;
    }

    .vertical-spacer2 {
        width: 5px;
        height: calc(100% - 10px);
        background-color: #bbb;
        border-radius: 5px;
    }
} //don't use spacers when using round buttons

@media (hover: hover) {
    .wifi-header__button:hover {
        transform: scale(1.1);
        background-color: #b9143b;
        border-radius: 10px;
    }
}

.wifi-header__home-button {
    display: block;
    margin-right: 10px;
    background-image: url("/../public/img/logos/logo_wifi_2021_monochrome.png");
    background-size: 70px;
    background-repeat: no-repeat;
    background-position: center center;
    height: 70px;
    width: 80px;
    transition: all 0.2s ease;
    cursor: pointer;

    @media (max-width: 900px) {
        margin: 0.2em 0 -0.2em;
        background-size: 80px;
    }

    // &.active {
    //     border-bottom: 2px solid white;
    // }
}

@media (hover: hover) {
    .wifi-header__home-button:hover {
        transform: scale(1.2);
    }
}

.wifi_picker {
    margin-top: 50px;
}