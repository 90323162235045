.documents-container {
  display: grid;
  padding: 1.375rem;
  gap: 2rem;
  grid-template-columns: 1fr;
  width: min-content;
  margin-inline: auto;
}

.documents-container .image-loader img {
    width: 100%;
}

@media (min-width: 1500px) {
  .documents-container {
    grid-template-columns: 1fr 1fr;
  }
}

.document-card {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  overflow: hidden;

  width: min(800px, 80vw);
  height: min(600px, 60vw);

  position: relative;
}

@media (min-width: 1500px) {
  .document-card {
    width: 480px;
    height: 360px;
  }
}

.document-card__badge {
  position: absolute;
  top: 0;
  left: 0;

  /* makes a triangle */
  --size: 118px;
  width: var(--size);
  height: var(--size);
  background: linear-gradient(
    to top left,
    rgba(0 0 0 / 0) 0%,
    rgba(0 0 0 / 0) 50%,
    #de1444 50%,
    #de1444 100%
  );
}

.document-card__badge span {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.6rem;
}

.document-card__title {
  position: absolute;
  bottom: 0;
  margin: 1rem;
  padding: 1rem;
  border-radius: 5px;
  background-color: #eee;
  display: flex;
  gap: 1rem;
  align-items: center;
  width: calc(100% - 4rem);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.document-card__title h2 {
  font-size: 1.6rem;
  font-weight: bold;
  color: #de1444;
}

.document-card__title img {
  display: block;
  height: 3rem;
}

.document-card__preview {
  display: block;
  width: 100%;
  height: 100%;
}
