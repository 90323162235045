@import "../../../shadows.scss";

$imageurl: url('/../public/img/team/2019_20/team.jpg?size=1920');

@function strip-unit($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

html {
  height: 100%;
}

#logo {
  // background-color: white;
  background-image: url('/../public/img/logos/logo_2019.png');
  width: 75%;
  resize: both;
  height: 50%;
  max-width: 624px;
  max-height: 624px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  position: absolute;
}

#logo_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

#slide_down_text {
  opacity: 0.7;
  position: absolute;
  bottom: 40px;
  color: white;
  z-index: 1;
}

#slide_down_icon {
  height: 30px;
  opacity: 0.7;
  position: absolute;
  bottom: 80px;
  color: white;
  z-index: 1;
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 1.5;

  @media (max-height: 400px) {
    height: 20px;
  }

  #wheel {
    animation: scroll ease 1.5s infinite;
  }
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(40px);
  }
}

#team_image_container {
  position: absolute;
  width: 100%;
  height: 100%;
  //overflow: hidden;
  position: sticky;
}

@keyframes team_image_enter {
    0% { opacity: 0; transform: scale(1.05);}
    100% { opacity: 1; transform: scale(1);}
}

#team_image {
  width: 100vw;
  background-size: cover;
  background-position: center;
  // background-image: $imageurl;
  filter: brightness(70%) grayscale(40%);
  height: 100vh;
  background-repeat: no-repeat;
  z-index: 0;
  opacity: 0.3;
  position:sticky;
  align-items: center;
  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
    animation:team_image_enter 2s;
  }
}

#logo_container + .section_topic {
  margin-top: 20px;
}

.cardgroup-small .minicard {
    height: 320px;
}

.minicard {
  @include shadow("nonclikable");
  background-color: white;
  width: 260px;
  padding: 20px;
  margin: 20px;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  align-self: stretch;

  &-icon {
    margin-top: 5px;
    height: 100px;
  }

  &-title {
    font-family: "Raleway", sans-serif;
    margin: 20px 0 20px 0;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
  }

  &-text {
    font-size: 14px;
    text-align: center;
    margin: 0 auto auto auto;
  }

  @media (max-width: 696px) {
    height: unset;
  }
}

.cardgroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  max-width: 1020px;
  margin: -20px 0;

  @media (max-width: 696px) {
    overflow: hidden;
  }
}

$imagew: image-width($imageurl);
$imageh: image-height($imageurl);
$imagew_l: strip-unit($imagew);
$imageh_l: strip-unit($imageh);

@media (max-aspect-ratio: (#{$imagew_l} / #{$imageh_l})) {
  #team_image {
    background-size: auto 100%;
    width: calc(100vh / #{$imageh_l} * #{$imagew_l});
    background-position: left;
    animation: movingbg 40s ease-in-out infinite;
    will-change: transform;
  }
}

@keyframes movingbg {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }

  50% {
    transform: translateX(calc(100vw - 100vh / #{$imageh_l} * #{$imagew_l}));
    -webkit-transform: translateX(calc(100vw - 100vh / #{$imageh_l} * #{$imagew_l}));
  }

  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }
}

@-webkit-keyframes movingbg {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }

  50% {
    transform: translateX(calc(100vw - 100vh / #{$imageh_l} * #{$imagew_l}));
    -webkit-transform: translateX(calc(100vw - 100vh / #{$imageh_l} * #{$imagew_l}));
  }

  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }
}

#moving_gradient {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: radial-gradient(#ff7f66, #a70028); //radial-gradient(#ff7f66, #ef4058);
  animation: moving 8s ease-in-out infinite;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  overflow: hidden;
}

@keyframes moving {
  0% {
    transform: scale3d(1, 1, 1);
    -webkit-transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(2, 2, 1);
    -webkit-transform: scale3d(2, 2, 1);
  }

  100% {
    transform: scale3d(1, 1, 1);
    -webkit-transform: scale3d(1, 1, 1);
  }
}

.card {
  @include shadow("nonclikable");
  margin-left: 20px;
  margin-right: 20px;
  background-color: white;
  border-radius: 20px;
  width: 100%;
  max-width: 1080px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 70px auto auto;
  grid-column-gap: 0;
  grid-row-gap: 0;
  overflow: hidden;

  &-image {
    grid-area: 1 / 1 / 4 / 2;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  &-image img {
    width: 100%;
    height: 100%;
}
  &-text {
    //grid-area: 2 / 2 / 4 / 3;
    grid-area: 1 / 2 / 4 / 3;
    padding: 20px;
  }

  &-title {
    display: none;

    grid-area: 1 / 2 / 2 / 3;
    //display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-left: 20px;
    padding-top: 20px;
    font-size: 35px;
  }

  @media (max-width: 1100px) {
    grid-template-rows: auto auto auto;

    &-image {
      min-height: 200px;
      grid-area: 1 / 1 / 2 / 3;
    }

    &-text {
      grid-area: 3 / 1 / 4 / 3;
    }

    &-title {
      grid-area: 2 / 1 / 3 / 3;
    }
  }
}

.buttonsgroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  max-width: 1020px;
  margin: -20px 0;

  a.button {
    width: 470px;
    margin: 20px;
    position: relative;

    @media (max-width: 1036px) {
      max-width: 640px;
      width: 100%;
    }

    div.img_container {
      @include shadow("button");
      position: relative;
      border-radius: 20px;
      overflow: hidden;
      background-color: white;
      height: 150px;
      transition: transform 0.225s ease-in-out !important;
      transform: scale(1) !important;
      z-index: 1;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-position: center;
      }
    }

    @media (hover: hover) {
      div.img_container:hover {
        transform: scale(1.05) !important;
      }
    }

    div.img_container::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(7deg, rgba(0, 0, 0, 1) 0%, rgba(100, 100, 100, 0) 60%, rgba(255, 255, 255, 0) 100%);
    }

    .button-text {
      position: absolute;
      display: flex;
      height: 130px;
      width: calc(100% - 40px);
      top: 0;
      left: 0;
      align-items: flex-end;
      padding: 0 20px 20px 20px;
      color: rgba(255, 255, 255, 1);
      font-size: 30px;
      font-weight: 700;
      font-family: "Raleway", sans-serif;
      pointer-events: none;
      z-index: 1;
    }
  }
}
