$logo_url: url('/../public/img/logos/logo_title.png');
$imagew: 312px;
$imagewandmenu: $imagew + 80px;
$radius: 30px;

.cd-header {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1500px;
  z-index: 9999;
  display: grid;
  grid-template-columns: 1fr 80px;
  grid-template-rows: 80px;
  grid-column-gap: 0;
  grid-row-gap: 0;
  pointer-events: none;
}

.logo-wrap {
  position: absolute;
  display: block;
  right: 0;
  top: 40px;
  cursor: pointer;
}

.logo-wrap a {
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  font-size: 20px;
  line-height: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fff;
  transition: all 0.3s ease-out;
}

.logo-wrap a span {
  color: #8167a9;
}

.logo-wrap a:hover {
  opacity: 0.9;
}

.nav-but-wrap {
  grid-area: 1 / 2 / 2 / 3;
  position: relative;
  pointer-events: all;
  display: inline-flex;
  float: right;
  justify-content: center;
  height: 80px;
  width: 80px;
  transition: border-radius linear 0.8s, background-color 0.3s ease;
  border-bottom-left-radius: $radius;

  @media (min-width: 1500px) {
    border-bottom-right-radius: $radius;
  }
}

.nav-but-wrap::before {
  opacity: 0;
  content: '';
  width: 80px;
  height: 80px;
  border-bottom-left-radius: $radius;
  z-index: -10;
  position: absolute;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  transition: border-radius linear 0.8s, box-shadow 0.3s ease;

  @media (min-width: 1500px) {
    border-bottom-right-radius: $radius;
  }
}

.nav-logo-image {
  background-image: $logo_url;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  margin: 3px 5px 7px 5px;
}

.nav-logo {
  opacity: 0;
  grid-area: 1 / 1 / 2 / 2;
  box-sizing: border-box;
  background-color: white;
  max-width: #{$imagew};
  height: 80px;
  transform: translateY(-100px);
  border-bottom-right-radius: $radius;
  transition: border-radius linear 0.8s, opacity 0.3s ease,
    transform 0.3s ease, color 0.3s ease, -webkit-transform 0.3s ease;

  @media (min-width: 1500px) {
    border-bottom-left-radius: $radius;
  }

  @media (max-width: $imagewandmenu) {
    border-bottom-right-radius: 0;
  }
}

.nav-logo::before {
  position: absolute;
  grid-area: 1 / 1 / 2 / 2;
  content: '';
  z-index: -10;
  box-sizing: border-box;
  width: 100%;
  max-width: #{$imagew};
  height: 80px;
  border-bottom-right-radius: $radius;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  transition: border-radius linear 0.8s;

  @media (min-width: 1500px) {
    border-bottom-left-radius: $radius;
  }

  @media (max-width: $imagewandmenu) {
    border-bottom-right-radius: 0;
  }
}

body:not(.nologo) {
  .nav-but-wrap {
    @media (max-width: $imagewandmenu) {
      border-bottom-left-radius: 0;
    }
  }

  .nav-but-wrap::before {
    @media (max-width: $imagewandmenu) {
      border-bottom-left-radius: 0;
    }
  }
}

body.nav-bg .nav-but-wrap {
  background-color: white;
}

body.nav-bg .nav-but-wrap::before {
  opacity: 1;
}

body.nav-bg .menu-icon__line {
  background-color: black;
}

body.nav-bg:not(.nologo) .nav-logo {
  opacity: 1;
  transform: translateY(0);
}

.menu-icon {
  width: 30px;
  position: relative;
  z-index: 2;
  cursor: pointer;
  align-self: center;
  display: block;
}

.menu-icon__line {
  height: 2px;
  width: 30px;
  display: block;
  background-color: white;
  margin-bottom: 7px;
  cursor: pointer;
  -webkit-transition: background-color 0.5s ease,
    -webkit-transform 0.2s ease;
  transition: background-color 0.5s ease, -webkit-transform 0.2s ease;
  transition: transform 0.2s ease, background-color 0.5s ease;
  transition: transform 0.2s ease, background-color 0.5s ease,
    -webkit-transform 0.2s ease;
}

.menu-icon__line_last {
  margin-bottom: 0;
}

.menu-icon__line-left {
  width: 16.5px;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.menu-icon__line-right {
  width: 16.5px;
  float: right;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
}

.menu-icon:hover .menu-icon__line-left,
.menu-icon:hover .menu-icon__line-right {
  width: 30px;
}

.nav {
  position: fixed;
  z-index: 1;
  pointer-events: all;
}

.nav::before,
.nav::after {
  content: '';
  position: fixed;
  top: 10px;
  right: 10px;
  width: 0;
  height: 0;
  background-color: rgba(20, 21, 26, 0.6);
  border-bottom-left-radius: 200%;
  z-index: -1;
  transition: border-radius ease 0.8s,
    width cubic-bezier(0.77, 0, 0.175, 1) 0.6s,
    height cubic-bezier(0.77, 0, 0.175, 1) 0.6s;
}

.nav::after {
  background-color: white;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 300%;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  box-shadow: 6px 7px 28px 0 rgba(16, 16, 16, 0.3);
}

.nav::before {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.nav__content {
  position: fixed;
  visibility: hidden;
  top: 90px;
  right: 10px;
  width: 300px;
  text-align: left;
}

.nav__list {
  position: relative;
  padding: 0;
  margin: 0;
  z-index: 2;
}

.nav__list-item {
  position: relative;
  display: block;
  opacity: 0;
  text-align: left;
  color: #fff;
  overflow: hidden;
  font-family: 'Poppins', sans-serif;
  font-size: 22px;
  line-height: 1.2;
  letter-spacing: 2px;
  -webkit-transform: translate(30px, 0%);
  transform: translate(30px, 0%);
  transition: opacity 0.1s ease, transform 0.2s ease;
  transition-delay: 0.2s;
  margin-top: 7px;
  margin-bottom: 7px;
}

.nav__list-item a {
  position: relative;
  text-decoration: none;
  color: black;
  overflow: hidden;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  z-index: 2;
  height: 28px;
  padding-left: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: inline-block;
  transition: all 200ms linear;
}

.nav__list-item a::after {
  position: absolute;
  content: '';
  top: 50%;
  left: 0;
  width: 5px;
  height: 0;
  opacity: 0;
  background: linear-gradient(288deg, #ff7f66, #de1444);
  z-index: 1;
  transition: all 200ms linear;
}

.nav__list-item a:hover::after {
  height: 100%;
  opacity: 1;
  top: 0;
}

.nav__list-item:not(.active-nav) a:hover {
  color: #770303;
  font-size: 23px;
  transition: color 100ms ease-in-out, font-size 100ms ease-in-out;
}

.nav__list-item.active-nav a {
  background: linear-gradient(288deg, #ff7f66, #de1444);
  background-clip: text;
  font-size: 25px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.nav__list-item.active-nav a::after {
  height: 100%;
  opacity: 1;
  top: 0;
}

.active-header .nav__content {
  visibility: visible;
}

.active-header .menu-icon__line {
  background-color: black;
  -webkit-transform: translate(0, 0) rotate(-45deg);
  transform: translate(0, 0) rotate(-45deg);
}

.active-header .menu-icon__line-left {
  width: 15px;
  -webkit-transform: translate(2px, 4px) rotate(45deg);
  transform: translate(2px, 4px) rotate(45deg);
}

.active-header .menu-icon__line-right {
  width: 15px;
  float: right;
  -webkit-transform: translate(-3px, -3.5px) rotate(45deg);
  transform: translate(-3px, -3.5px) rotate(45deg);
}

.active-header .menu-icon:hover .menu-icon__line-left,
.active-header .menu-icon:hover .menu-icon__line-right {
  width: 15px;
}

.active-header .nav {
  visibility: visible;
}

.active-header .nav::before,
.active-header .nav::after {
  width: 300px;
  height: 500px;
  border-radius: 15px;
}

.active-header .nav::after {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.active-header .nav::before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.active-header .nav__list-item {
  opacity: 1;
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
  -webkit-transition: opacity 0.3s ease, color 0.3s ease,
    -webkit-transform 0.3s ease;
  transition: opacity 0.3s ease, color 0.3s ease,
    -webkit-transform 0.3s ease;
  transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease;
  transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease,
    -webkit-transform 0.3s ease;
}

.active-header .nav__list-item:nth-child(0) {
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.active-header .nav__list-item:nth-child(1) {
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.active-header .nav__list-item:nth-child(2) {
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}

.active-header .nav__list-item:nth-child(3) {
  -webkit-transition-delay: 0.7s;
  transition-delay: 0.7s;
}

.active-header .nav__list-item:nth-child(4) {
  -webkit-transition-delay: 0.8s;
  transition-delay: 0.8s;
}

.active-header .nav__list-item:nth-child(5) {
  -webkit-transition-delay: 0.9s;
  transition-delay: 0.9s;
}

.active-header .nav__list-item:nth-child(6) {
  -webkit-transition-delay: 1s;
  transition-delay: 1s;
}

.active-header .nav__list-item:nth-child(7) {
  -webkit-transition-delay: 1.1s;
  transition-delay: 1.1s;
}

.active-header .nav__list-item:nth-child(8) {
  -webkit-transition-delay: 1.2s;
  transition-delay: 1.2s;
}

.active-header .nav__list-item:nth-child(9) {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.active-header .nav__list-item:nth-child(10) {
  -webkit-transition-delay: 1.4s;
  transition-delay: 1.4s;
}
