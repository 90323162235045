.loading-container {
  display: grid;
  place-items: center;
  padding-top: 10rem;
}

.loading {
  height: 160px;
  width: 160px;
  border: 4px solid #FFEBEE;
  border-top: 4px solid #F04D56;
  border-bottom: 4px solid #F04D56;
  border-radius: 50%;
  animation: spin 1s infinite ease-in-out;
}

@keyframes spin {
  from {
    transform: rotate(0deg); 
  }
  to {
    transform: rotate(360deg); 
  }
}