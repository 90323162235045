.human-cardcontainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  align-items: flex-start;
}

#teamcontainer {
  // display: grid;
  // grid-template-columns: repeat(2, 1fr);
  // grid-template-rows: 1fr;
  // grid-column-gap: 0px;
  // grid-row-gap: 0px;
  max-width: 1500px;
  margin: auto;

  .spacer {
    margin: 20px 20px 20px 20px;
    width: calc(100% - 40px);
    height: 1px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  // p.gridleft
  // {
  //     grid-area: 1 / 1 / 2 / 2;
  // }
  // div.gridleft
  // {
  //     grid-area: 2 / 1 / 3 / 2;
  // }
  // p.gridright
  // {
  //     grid-area: 1 / 2 / 2 / 3;
  // }
  // div.gridright
  // {
  //     grid-area: 2 / 2 / 3 / 3;
  // }
}

.human-card {
  width: 350px;
  height: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  margin: 20px;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 300px 100px;
  grid-column-gap: 0;
  grid-row-gap: 0;
  transform-style: preserve-3d;
  border-radius: 5px;
  cursor: pointer;

  &.open:not(.hover) {
    .moreinfo {
      opacity: 1;
      background-color: white;
      margin-top: 19px;
      transform: translate3d(0, -6px, 30px);
    }

    .info {
      border-radius: 0;
      margin: 11px 11px 7px 11px !important;
      padding-bottom: 4px;
      transform: translate3d(0, -6px, 30px);

      @supports (-ms-ime-align: auto) {
        margin: 11px 11px 11px 11px !important;
        padding-bottom: 0;
        transform: translate3d(0, 0, 30px);
      }
    }
  }

  &.open.hover {
    .moreinfo {
      opacity: 1;
      background-color: white;
      margin: 10px 8px -15px 8px;
      transform: translateZ(30px);
    }
  }

  &.open {
    transform: scale(1.1) !important;
  }

  .moreinfo {
    grid-area: 1 / 1 / 2 / 2;
    z-index: 3;
    opacity: 0;
    margin: 312px 11px -12px 11px;
    padding: 15px;
    transition: all 0.2s ease-in-out;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    @supports (-ms-ime-align: auto) {
      margin: 319px 11px -19px 11px;
    }
  }

  .info {
    margin: 15px;
    grid-area: 2 / 1 / 3 / 2;
    z-index: 2;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);

    transition: all 0.2s ease-in-out;

    display: grid;
    grid-template-columns: 70px 1fr;
    grid-template-rows: auto;
    grid-column-gap: 0;
    grid-row-gap: 0;

    overflow: hidden;

    img {
      grid-area: 1 / 1 / 2 / 2;
      width: calc(100% - 30px);
      height: calc(100% - 30px);
      padding: 15px;
      object-position: center;
      object-fit: contain;
      filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.15));
      pointer-events: none;
      overflow: hidden;
    }

    div.box {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-content: flex-start;
      align-items: flex-start;
      // fixes a weird bug when sometimes the height doesn't automatically set
      // to 70px and stays 100px until hover event happens
      height: 70px;
      padding-left: 10px;
    }

    p {
      &.title {
        font-family: 'Raleway', sans-serif;
        margin: 0;
        font-size: 13px;
        font-weight: 600;

        color: rgba(0, 0, 0, 0.7);

        @media (max-width: 425px) {
          font-size: 10px;
        }
      }

      &.name {
        margin: 0;
        font-size: 20px;
        font-weight: 600;

        @media (max-width: 425px) {
          font-size: 18px;
        }
      }
    }
  }

  .image-loader {
    object-position: center;
    grid-area: 1 / 1 / 3 / 2;
    z-index: 1;
    pointer-events: none;
  }

  .image-loader img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: fixed;
  }

  &.hover {
    .info {
      border-radius: 0;
      margin: 8px;
      transform: translate3d(0, 0, 30px);
    }

    transform: scale(1.08) !important;
  }
}

@media (hover: hover) {
  .human-card:hover {
    .info {
      border-radius: 0;
      margin: 11px;
      transform: translate3d(0, -6px, 30px);

      @supports (-ms-ime-align: auto) {
        transform: translate3d(0, 0, 30px);
      }
    }

    @supports (-ms-ime-align: auto) {
      transform: scale(1.05) !important;
    }
  }
}

p.category {
  font-weight: 700;
  margin: 0;
  font-size: 45px;
  color: black;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-decoration: none;
  -webkit-animation: tracking-in-contract 0.8s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-contract 0.8s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;

  text-align: center;
  padding: 20px 0 20px 0;
  background: linear-gradient(288deg, #ff7f66, #de1444);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 450px) {
    font-size: 2.1em;
    height: auto;
  }
}

@keyframes tracking-in-contract {
  0% {
    letter-spacing: 1em;
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}

.teampicker {
  margin-top: 40px;

  &_dropdown {
    position: relative;
    display: block;
    margin: 0 auto;
    width: 280px;

    &::before {
      display: block;
      content: '';
      width: 100%;
      z-index: 3;
      background: linear-gradient(270deg, #ff7f66, #de1444);
      height: 2px;
      position: absolute;
    }

    &_button {
      background-color: white;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
      border: none;
      border-radius: 0;
      color: rgba(0, 0, 0, 0.9);
      padding: 16px;
      font-size: 16px;
      position: relative;
      cursor: pointer;
      transition: background-color ease-in-out 0.1s,
        box-shadow ease-in-out 0.2s;
      width: 100%;
      z-index: 2;
    }

    &_content {
      transform: scaleY(0);
      transform-origin: top;
      opacity: 0;
      position: absolute;
      background-color: white;
      min-width: 280px;
      overflow-y: auto;
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
      margin: 0;
      z-index: 1;
      max-height: 200px;

      transition: transform ease-in-out 0.15s,
        opacity ease-in-out 0.1s;

      a {
        color: black;
        padding: 12px 16px;
        text-align: center;
        text-decoration: none;
        display: block;
        transition: background-color ease-in-out 0.1s;

        &:hover {
          background-color: whitesmoke;
        }
      }
    }

    &:hover {
      .teampicker_dropdown_content {
        transform: scaleY(1);
        opacity: 1;
      }

      .teampicker_dropdown_button {
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
      }
    }
  }
}
