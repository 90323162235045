.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10rem;
}

.error__msg {
  padding: 1rem;
  border-radius: 1rem;
  border: 1px solid #aaa;
}