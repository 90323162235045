.collapsed {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
}

.image-loader {
    display: grid;
    place-items: center;
}

.image-loader-thumbnail {
    filter: blur(0.2rem);
}

.image-loader-circle {
    position: absolute;
    object-position: center;
    width: 100px;
    height: 100px;
    animation: spin 1s infinite ease-in-out;
    border: 4px solid #ffebee;
    border-top: 4px solid #F04D56;
    border-bottom: 4px solid #F04D56;
    border-radius: 50%;
}

@keyframes spin {
    from {
      transform: rotate(0deg); 
    }
    to {
      transform: rotate(360deg); 
    }
  }